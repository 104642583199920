<template>
	<div>
		<b-modal
			id="modal-update-bank-manager"
			cancel-variant="outline-secondary"
			:ok-title="$t('Update')"
			:cancel-title="$t('Close')"
			size="lg"
			ok-variant="primary"
			centered
			:hide-footer="!$can('update', 'bank')"
			modal-class="modal-primary"
			:title="$t('Update Bank Manager')"
			@ok="confirmUpdate"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="updateBankManagerModal">
				<b-form method="POST" @submit.prevent="validationForm">
					<b-form-group label-for="code">
						<label class="mb-1">{{ $t("Code") }}</label>
						<validation-provider
							#default="{ errors }"
							name="code"
							rules="required"
						>
							<b-form-input
								id="code"
								v-model="bankManager.codeUpdate"
								disabled
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label-for="name">
						<label class="mb-1">{{ $t("Bank Name") }}</label>
						<validation-provider
							#default="{ errors }"
							name="name"
							rules="required"
						>
							<b-form-input
								id="name"
								v-model="bankManager.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>

					<b-form-group label-for="eBank">
						<label class="mb-1">{{ $t("E Bank") }}</label>

						<b-form-input
							id="eBank"
							v-model="bankManager.eBank"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						/>
					</b-form-group>
					<span class="text-danger" v-if="showErrorUrl" style="font-size: 12px"
						>Field must be URL</span
					>
					<b-form-group label-for="bank_logo">
						<label for=""
							><span class="text-danger">*</span> {{ $t("Bank Logo") }}
							<span class="text-secondary">(Không > 10Mb)</span></label
						>
						<validation-provider
							#default="{ errors }"
							name="bank_logo"
							rules="size:10240"
						>
							<b-form-file
								v-model="bankManager.bank_logo"
								input-id="bankLogo"
								accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group>
						<b-img
							:src="`${bankManager.bank_logo ? bankManager.showImg : ''}`"
							fluid
							style="width: 150px"
							class="mr-2 rounded"
						/>
					</b-form-group>
					<b-button
						v-show="bankManager.bank_logo"
						variant="outline-danger"
						class="btn-sm btn-icon"
						@click="bankManager.bank_logo = null"
					>
						<feather-icon icon="XIcon" />
					</b-button>

					<b-form-group label-for="status">
						<label class="mb-1 d-block">{{ $t("Status") }}</label>
						<b-form-radio
							v-model="bankManager.stat"
							name="status"
							value="1"
							inline
						>
							Active
						</b-form-radio>
						<b-form-radio
							v-model="bankManager.stat"
							name="status"
							value="2"
							inline
						>
							Inactive
						</b-form-radio>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import {
	ValidationProvider,
	ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
	BLink,
	BRow,
	BCol,
	BTabs,
	BTab,
	BImg,
	BForm,
	BFormGroup,
	BFormInput,
	BFormFile,
	BFormRadio,
	BButton,
	BInputGroup,
	BInputGroupPrepend,
	BModal,
	VBModal,
	BInputGroupAppend,
	BFormTextarea,
	BBadge,
} from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import baseUrl from "@/libs/base-url"
export default {
	components: {
		BLink,
		BRow,
		BCol,
		BTabs,
		BTab,
		BImg,
		BForm,
		BFormGroup,
		BFormInput,
		BFormFile,
		BFormRadio,
		BButton,
		BInputGroup,
		BInputGroupPrepend,
		BModal,
		BFormTextarea,
		BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		"b-modal": VBModal,
	},
	props: {
		bankManagerDetail: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			bankManager: {
				id: "",
				codeUpdate: "",
				name: "",
				eBank: "",
				stat: "",
				bank_logo: "",
				showImg: null,
			},
			showErrorUrl: false,
		};
	},
	watch: {
		bankManagerDetail: {
			handler(newVal) {
				if (newVal) {
					this.bankManager = {
						id: newVal.id,
						codeUpdate: newVal.code,
						name: newVal.bank_name,
						bank_logo: "",
						showImg: baseUrl + newVal.bank_logo,
						eBank: newVal.e_banking,
						stat: newVal.stat,
					};
				}
			},
			deep: true,
		},
	},
	methods: {
		isValidUrl(urlString) {
			var urlPattern = new RegExp(
				"^(https?:\\/\\/)?" +
					"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
					"((\\d{1,3}\\.){3}\\d{1,3}))" +
					"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
					"(\\?[;&a-z\\d%_.~+=-]*)?" +
					"(\\#[-a-z\\d_]*)?$",
				"i"
			);
			return !!urlPattern.test(urlString)
		},
		validationForm() {
			this.$refs.updateBankManagerModal.validate().then((success) => {
				if (success) {
					if (this.bankManager.eBank) {
						if (this.isValidUrl(this.bankManager.eBank) == false) {
							this.showErrorUrl = true
							return
						} else {
							this.updateBankManager(this.bankManager)
						}
					} else {
						this.updateBankManager(this.bankManager)
					}
				}
			});
		},
		async updateBankManager(bankManagerData) {
			await this.$store
				.dispatch("payment-bank-manager/updateBankManager", bankManagerData)
				.then((response) => {
					if (response.status === 200) {
						if (response.data.code == "00") {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || "success",
									icon: "CheckIcon",
									variant: "success",
								},
							});
							this.$emit("refetch-data")
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide("modal-update-bank-manager")
									this.resetModal()
								}, "500")
							});
							this.$emit("refetch-data")
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: "AlertTriangleIcon",
									variant: "danger",
								},
							});
						}
					}
				});
		},
		resetModal() {
			this.bankManager = {
				id: "",
				name: "",
				codeUpdate: "",
				bank_logo: null,
				eBank: "",
				stat: "",
			};
			this.$emit("update:bankManagerDetail", null);
		},
		confirmUpdate(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		},
	},
	setup() {
		return {
			baseUrl,
		};
	},
};
</script>